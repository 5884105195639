import React from 'react';
import { JSXMapSerializer, PrismicRichText } from '@prismicio/react';
import { animated, useInView } from '@react-spring/web';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  projectHighlightsTitle,
  projectHighlightsContainer,
  projectHighlightsImage,
  projectHighlightsTitleContainer,
  projectsContainer,
  projectHighlightsInfoContainer,
  projectCategoryDetails,
  projectLocationDetails,
  projectOtherDetails,
  projectDetailsContainer,
  animateSpotlight,
  fontFormat,
  longTitle,
} from './styles.module.css';
import '../../../styles/global.css';

import { IProjectSpotlightsData } from '../../../interfaces/project-spotlight';

const ProjectSpotlightsSection = ({ data }: IProjectSpotlightsData) => {
  const {
    first_spotlight_title,
    first_spotlight_image,
    first_spotlight_project_category,
    first_spotlight_project_category_name,
    first_spotlight_project_location,
    first_spotlight_project_location_name,
    first_spotlight_other_info,
    first_spotlight_other_info_name,
    first_spotlight_link,
    second_spotlight_title,
    second_spotlight_image,
    second_spotlight_project_category,
    second_spotlight_project_category_name,
    second_spotlight_project_location,
    second_spotlight_project_location_name,
    second_spotlight_other_info,
    second_spotlight_other_info_name,
    second_spotlight_link,
    third_spotlight_title,
    third_spotlight_image,
    third_spotlight_project_category,
    third_spotlight_project_category_name,
    third_spotlight_project_location,
    third_spotlight_project_location_name,
    third_spotlight_other_info,
    third_spotlight_other_info_name,
    third_spotlight_link,
  } = data;

  const firstSpotlightImage = first_spotlight_image?.gatsbyImageData;
  const secondSpotlightImage = second_spotlight_image?.gatsbyImageData;
  const thirdSpotlightImage = third_spotlight_image?.gatsbyImageData;

  const components: JSXMapSerializer = {
    heading1: ({ children }) => (
      <h1 className={projectHighlightsTitle}>{children}</h1>
    ),
    label: ({ node, children }) => (
      <span className={node?.data?.label === 'long-title' ? longTitle : ''}>
        {children}
      </span>
    ),
  };

  const headingSerializer: JSXMapSerializer = {
    label: ({ node, children }) => (
      <span className={node?.data?.label === 'font-format' ? fontFormat : ''}>
        {children}
      </span>
    ),
  };

  // Project Spotlight Title and Details Animation (Individual Refs)
  const [firstSpotlightTitle, firstSpotlightTitleSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 200,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '150px 0px 150px 0px',
    }
  );

  const [firstSpotlightDetails, firstSpotlightDetailsSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 200,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '50px 0px 600px 0px',
    }
  );

  const [secondSpotlightTitle, secondSpotlightTitleSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 200,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '150px 0px 150px 0px',
    }
  );

  const [secondSpotlightDetails, secondSpotlightDetailsSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 200,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '50px 0px 600px 0px',
    }
  );

  const [thirdSpotlightTitle, thirdSpotlightTitleSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 200,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '150px 0px 150px 0px',
    }
  );

  const [thirdSpotlightDetails, thirdSpotlightDetailsSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 200,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '50px 0px 600px 0px',
    }
  );

  return (
    <div className={projectHighlightsContainer}>
      <div className={projectsContainer}>
        <Link to={`/portfolio${first_spotlight_link?.url}`}>
          <div className={projectHighlightsTitleContainer}>
            <animated.div
              ref={firstSpotlightTitle}
              style={firstSpotlightTitleSprings}
              className={animateSpotlight}
            >
              <PrismicRichText
                field={first_spotlight_title?.richText}
                components={components}
              />
            </animated.div>
          </div>
          <div>
            <GatsbyImage
              image={firstSpotlightImage}
              alt={first_spotlight_image?.alt}
              className={projectHighlightsImage}
            />
          </div>
        </Link>
        <animated.div
          ref={firstSpotlightDetails}
          style={firstSpotlightDetailsSprings}
          className={animateSpotlight}
        >
          <div className={projectHighlightsInfoContainer}>
            <div className={projectDetailsContainer}>
              <div className={projectCategoryDetails}>
                <PrismicRichText
                  field={first_spotlight_project_category?.richText}
                />
                <PrismicRichText
                  field={first_spotlight_project_category_name?.richText}
                  components={headingSerializer}
                />
              </div>
              <div className={projectLocationDetails}>
                <PrismicRichText
                  field={first_spotlight_project_location?.richText}
                />
                <PrismicRichText
                  field={first_spotlight_project_location_name?.richText}
                  components={headingSerializer}
                />
              </div>
              <div className={projectOtherDetails}>
                <PrismicRichText field={first_spotlight_other_info?.richText} />
                <PrismicRichText
                  field={first_spotlight_other_info_name?.richText}
                  components={headingSerializer}
                />
              </div>
            </div>
          </div>
        </animated.div>
      </div>
      <div className={projectsContainer}>
        <Link to={`/portfolio${second_spotlight_link?.url}`}>
          <div className={projectHighlightsTitleContainer}>
            <animated.div
              ref={secondSpotlightTitle}
              style={secondSpotlightTitleSprings}
              className={animateSpotlight}
            >
              <PrismicRichText
                field={second_spotlight_title?.richText}
                components={components}
              />
            </animated.div>
          </div>
          <div>
            <GatsbyImage
              image={secondSpotlightImage}
              alt={second_spotlight_image?.alt}
              className={projectHighlightsImage}
            />
          </div>
        </Link>
        <animated.div
          ref={secondSpotlightDetails}
          style={secondSpotlightDetailsSprings}
          className={animateSpotlight}
        >
          <div className={projectHighlightsInfoContainer}>
            <div className={projectDetailsContainer}>
              <div className={projectCategoryDetails}>
                <PrismicRichText
                  field={second_spotlight_project_category?.richText}
                />
                <PrismicRichText
                  field={second_spotlight_project_category_name?.richText}
                  components={headingSerializer}
                />
              </div>
              <div className={projectLocationDetails}>
                <PrismicRichText
                  field={second_spotlight_project_location?.richText}
                />
                <PrismicRichText
                  field={second_spotlight_project_location_name?.richText}
                  components={headingSerializer}
                />
              </div>
              <div className={projectOtherDetails}>
                <PrismicRichText
                  field={second_spotlight_other_info?.richText}
                />
                <PrismicRichText
                  field={second_spotlight_other_info_name?.richText}
                  components={headingSerializer}
                />
              </div>
            </div>
          </div>
        </animated.div>
      </div>
      <div className={projectsContainer}>
        <Link to={`/portfolio${third_spotlight_link?.url}`}>
          <div className={projectHighlightsTitleContainer}>
            <animated.div
              ref={thirdSpotlightTitle}
              style={thirdSpotlightTitleSprings}
              className={animateSpotlight}
            >
              <PrismicRichText
                field={third_spotlight_title?.richText}
                components={components}
              />
            </animated.div>
          </div>
          <div>
            <GatsbyImage
              image={thirdSpotlightImage}
              alt={third_spotlight_image?.alt}
              className={projectHighlightsImage}
            />
          </div>
        </Link>
        <animated.div
          ref={thirdSpotlightDetails}
          style={thirdSpotlightDetailsSprings}
          className={animateSpotlight}
        >
          <div className={projectHighlightsInfoContainer}>
            <div className={projectDetailsContainer}>
              <div className={projectCategoryDetails}>
                <PrismicRichText
                  field={third_spotlight_project_category?.richText}
                />
                <PrismicRichText
                  field={third_spotlight_project_category_name?.richText}
                  components={headingSerializer}
                />
              </div>
              <div className={projectLocationDetails}>
                <PrismicRichText
                  field={third_spotlight_project_location?.richText}
                />
                <PrismicRichText
                  field={third_spotlight_project_location_name?.richText}
                  components={headingSerializer}
                />
              </div>
              <div className={projectOtherDetails}>
                <PrismicRichText field={third_spotlight_other_info?.richText} />
                <PrismicRichText
                  field={third_spotlight_other_info_name?.richText}
                  components={headingSerializer}
                />
              </div>
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default ProjectSpotlightsSection;
