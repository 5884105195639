import { graphql, useStaticQuery } from 'gatsby';

export const usePortfolioPageQuery = () => {
  const { prismicPortfolioPage, allPrismicPortfolioDetailsPage } =
    useStaticQuery(graphql`
      query PortfolioQuery {
        prismicPortfolioPage {
          data {
            page_title {
              richText
            }
            page_description {
              richText
            }
          }
        }
        allPrismicPortfolioDetailsPage {
          edges {
            node {
              uid
              data {
                category_filter {
                  text
                }
                project_detail_name {
                  text
                }
                portfolio_detail_description {
                  text
                }
                portfolio_grid_image {
                  gatsbyImageData
                  alt
                }
              }
            }
          }
        }
      }
    `);

  return [prismicPortfolioPage?.data, allPrismicPortfolioDetailsPage?.edges];
};
