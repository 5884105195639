import React, { useState, useEffect } from 'react';
import type { HeadFC } from 'gatsby';
import { JSXMapSerializer, PrismicRichText } from '@prismicio/react';
import { animated, useInView } from '@react-spring/web';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import {
  servicesContainer,
  titleContainer,
  titleWrapper,
  title,
  infoContainer,
  serviceImageContainer,
  aboutContainer,
  imageBack,
  imageMiddle,
  imageFront,
  aboutImageContainer,
  aboutImages,
  aboutTitleContainer,
  aboutImageTitleContainer,
  aboutImage,
  aboutDescContainer,
  aboutTopContainer,
  aboutBottomContainer,
  imageBackContainer,
  imageMiddleContainer,
  imageFrontContainer,
  paragraphCardsContainer,
  paragraphCardTop,
  paragraphCardBottom,
  serviceInfoTitle,
  serviceInfoParagraph,
  serviceInfoParagraphHidden,
  teamImgBottom,
  fontFormat,
  serviceInfoList,
  showBtn,
  weBelieveContainer,
  teamTitleContainer,
  aboutMeetContainer,
  infoContentContainer,
  serviceCenter,
  teamImgTopContainer,
  serviceCenterBorder,
  serviceImageTitleContainer,
  fullWidth,
  noWidth,
  animateAboutUs,
  imageInfoService,
  imageService,
} from './styles.module.css';
import '../../../styles/global.css';

import { ServicesData } from '../../../interfaces/services';

import { useMediaQuery } from '../../../hooks/use-media-query';

const ServicesPage = ({ data }: ServicesData) => {
  const isDesktop = useMediaQuery('(min-width: 1300px)');

  const {
    about_image_back,
    about_image_middle,
    about_image_front,
    page_title,
    about_title,
    about_title_image,
    about_description,
    about_top,
    about_bottom,
    paragraph_description_top,
    paragraph_description_bottom,
    team_image_top,
    team_image_bottom,
    team_title,
    service_info_group,
    service_image,
  } = data;

  const aboutTitleImage = about_title_image?.gatsbyImageData;
  const aboutImageBack = about_image_back?.gatsbyImageData;
  const aboutImageMIddle = about_image_middle?.gatsbyImageData;
  const aboutImageFront = about_image_front?.gatsbyImageData;
  const teamImageTop = team_image_top?.gatsbyImageData;
  const teamImageTopMobile =
    team_image_top?.thumbnails?.mobile?.gatsbyImageData;
  const teamImageBottom = team_image_bottom?.gatsbyImageData;
  const teamImageBottomMobile =
    team_image_bottom?.thumbnails?.mobile?.gatsbyImageData;
  const serviceImage = service_image?.gatsbyImageData;

  //TODO: Improve implementation of show more
  const [isShowMore, setIsShowMore] = useState<boolean[]>([]);

  const components: JSXMapSerializer = {
    label: ({ node, children }) => (
      <span className={node?.data?.label === 'font-format' ? fontFormat : ''}>
        {children}
      </span>
    ),
  };

  const servicesSerializer: JSXMapSerializer = {
    span: ({ text }: any) => {
      return isShowMore ? text : `${text.substring(0, 140)}...`;
    },
  };

  const headingsSerializer: JSXMapSerializer = {
    heading1: ({ children }) => <h1 className={title}>{children}</h1>,
  };

  const teamImgTopResponsive = withArtDirection(teamImageTop, [
    {
      media: '(max-width: 1200px)',
      image: teamImageTopMobile,
    },
  ]);

  const teamImgBtmResponsive = withArtDirection(teamImageBottom, [
    {
      media: '(max-width: 768px)',
      image: teamImageBottomMobile,
    },
  ]);

  const [isHover, setIsHover] = useState({});

  const mouseOver = (event: any, index: number) => {
    setIsHover((prevValue) => {
      return {
        ...prevValue,
        [index]: true,
      };
    });
  };

  const mouseOut = (event: any, index: number) => {
    setIsHover((prevValue) => {
      return {
        ...prevValue,
        [index]: false,
      };
    });
  };

  const [aboutImgBackRef, aboutImgBackSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: 270,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    {
      rootMargin: '0% 50%',
      threshold: 0.5,
      once: true,
    }
  );

  const [aboutImgMiddleRef, aboutImgMiddleSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -270,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    {
      rootMargin: '0% 50%',
      threshold: 0.5,
      once: true,
    }
  );

  const [aboutImgFrontRef, aboutImgFrontSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: 270,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    {
      rootMargin: '0% 50%',
      threshold: 0.5,
      once: true,
    }
  );

  const [aboutTitleRef, aboutTitleSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 150,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '100px 0px 100px 0px',
    }
  );

  const [aboutUsSecondImage, aboutUsSecondImageSprings] = useInView(
    () => ({
      from: {
        y: 300,
      },
      to: {
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '100px 0px 200px 0px',
    }
  );

  const [aboutUsDescription, aboutUsDescriptionSprings] = useInView(
    () => ({
      from: {
        y: 200,
      },
      to: {
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '50px 0px 600px 0px',
    }
  );

  const [ourTeamTitle, ourTeamSprings] = useInView(
    () => ({
      from: {
        y: 200,
      },
      to: {
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '100px 0px 100px 0px',
    }
  );

  const [servicesTitle, servicesSprings] = useInView(
    () => ({
      from: {
        y: 200,
      },
      to: {
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '100px 0px 100px 0px',
    }
  );

  const handleShow = (position: number) => {
    setIsShowMore((prevArray) => {
      const newShowArray = prevArray?.map((value, index) => {
        if (index === position) {
          return !value;
        } else {
          return value;
        }
      });
      return newShowArray;
    });
  };

  useEffect(() => {
    const initShowArray = service_info_group?.map(() => false);
    setIsShowMore(initShowArray);
  }, []);

  return (
    <main>
      <div className={servicesContainer}>
        <div className={aboutContainer} id="about-us-page">
          <animated.div
            ref={aboutTitleRef}
            className={animateAboutUs}
            style={aboutTitleSprings}
          >
            <div className={aboutTitleContainer}>
              <PrismicRichText
                field={about_title?.richText}
                components={components}
              />
              <div className={aboutImageTitleContainer}>
                <GatsbyImage
                  image={aboutTitleImage}
                  alt={about_title_image?.alt}
                  className={aboutImage}
                />
              </div>
            </div>
            <div className={aboutMeetContainer}>
              <div className={aboutTopContainer}>
                <PrismicRichText
                  field={about_top?.richText}
                  components={components}
                />
              </div>
              <div className={aboutBottomContainer}>
                <PrismicRichText
                  field={about_bottom?.richText}
                  components={components}
                />
              </div>
            </div>
            <div className={aboutDescContainer}>
              <PrismicRichText field={about_description?.richText} />
            </div>
          </animated.div>
        </div>

        <div className={aboutImageContainer}>
          {isDesktop ? (
            <div className={aboutImages}>
              <animated.div
                className={imageBackContainer}
                ref={aboutImgBackRef}
                style={aboutImgBackSprings}
              >
                <GatsbyImage
                  image={aboutImageBack}
                  alt={about_image_back?.alt}
                  className={imageBack}
                />
              </animated.div>
              <animated.div
                className={imageMiddleContainer}
                ref={aboutImgMiddleRef}
                style={aboutImgMiddleSprings}
              >
                <GatsbyImage
                  image={aboutImageMIddle}
                  alt={about_image_middle?.alt}
                  className={imageMiddle}
                />
              </animated.div>
              <animated.div
                className={imageFrontContainer}
                ref={aboutImgFrontRef}
                style={aboutImgFrontSprings}
              >
                <GatsbyImage
                  image={aboutImageFront}
                  alt={about_image_front?.alt}
                  className={imageFront}
                />
              </animated.div>
            </div>
          ) : (
            <div className={aboutImages}>
              <div className={imageBackContainer}>
                <GatsbyImage
                  image={aboutImageBack}
                  alt={about_image_back?.alt}
                  className={imageBack}
                />
              </div>
              <div className={imageMiddleContainer}>
                <GatsbyImage
                  image={aboutImageMIddle}
                  alt={about_image_middle?.alt}
                  className={imageMiddle}
                />
              </div>
              <div className={imageFrontContainer}>
                <GatsbyImage
                  image={aboutImageFront}
                  alt={about_image_front?.alt}
                  className={imageFront}
                />
              </div>
            </div>
          )}
        </div>
        {/* Client Requested to Hide this Section because they don't know what to do with this Section yet */}
        {/* <div className={weBelieveContainer}>
          <animated.div
            ref={aboutUsSecondImage}
            className={animateAboutUs}
            style={aboutUsSecondImageSprings}
          >
            <GatsbyImage
              image={teamImgTopResponsive}
              alt={team_image_top?.alt}
              className={teamImgTopContainer}
            />
          </animated.div>
          <animated.div
            ref={aboutUsDescription}
            className={animateAboutUs}
            style={aboutUsDescriptionSprings}
          >
            <div className={paragraphCardsContainer}>
              <div className={paragraphCardTop}>
                <PrismicRichText
                  field={paragraph_description_top?.richText}
                  components={components}
                />
              </div>
              <div className={paragraphCardBottom}>
                <PrismicRichText
                  field={paragraph_description_bottom?.richText}
                />
              </div>
            </div>
          </animated.div>
        </div> */}
        <animated.div
          ref={ourTeamTitle}
          className={animateAboutUs}
          style={ourTeamSprings}
        >
          <div className={teamTitleContainer}>
            <PrismicRichText
              field={team_title?.richText}
              components={headingsSerializer}
            />
          </div>
        </animated.div>
        <GatsbyImage
          image={teamImgBtmResponsive}
          alt={team_image_bottom?.alt}
          className={teamImgBottom}
        />
        <div className={titleContainer}>
          <div className={titleWrapper} id="services-page">
            <animated.div
              ref={servicesTitle}
              className={animateAboutUs}
              style={servicesSprings}
            >
              <PrismicRichText
                field={page_title?.richText}
                components={headingsSerializer}
              />
            </animated.div>
          </div>
          <div className={serviceImageTitleContainer}>
            <GatsbyImage
              image={serviceImage}
              alt={service_image?.alt}
              className={imageService}
            />
          </div>
        </div>
        <div className={infoContainer}>
          {service_info_group?.map((item, index) => {
            const serviceInfoImage = item?.service_info_image?.gatsbyImageData;
            return (
              <div key={index}>
                <div
                  className={serviceCenter}
                  onMouseEnter={(e) => {
                    mouseOver(e, index);
                  }}
                  onMouseLeave={(e) => {
                    mouseOut(e, index);
                  }}
                >
                  <div className={serviceImageContainer}>
                    <GatsbyImage
                      image={serviceInfoImage}
                      alt={item?.service_info_image?.alt}
                      className={imageInfoService}
                    />
                  </div>
                  <div className={infoContentContainer}>
                    <div className={serviceInfoTitle}>
                      <PrismicRichText
                        field={item?.service_info_title?.richText}
                      />
                    </div>
                    {/* TODO: Improve showing more of text functionality using CSS 
                    manipulation instead of cutting text using javascript substring */}
                    <div
                      className={
                        isShowMore[index]
                          ? serviceInfoParagraph
                          : serviceInfoParagraphHidden
                      }
                    >
                      <PrismicRichText
                        field={item?.service_info_paragraph?.richText}
                        components={servicesSerializer}
                      />

                      <PrismicRichText
                        field={item?.service_info_second_paragraph?.richText}
                      />
                      <div className={serviceInfoList}>
                        <PrismicRichText
                          field={item?.service_info_list?.richText}
                        />
                      </div>
                      {!isShowMore[index] && <span>...</span>}
                    </div>
                    {item?.service_info_paragraph?.text.length >= 140 && (
                      <button
                        className={showBtn}
                        onClick={() => handleShow(index)}
                      >
                        {isShowMore[index] ? '' : 'Show More'}
                      </button>
                    )}
                  </div>
                </div>
                {isDesktop && (
                  <div className={serviceCenterBorder}>
                    <hr
                      color="#070707"
                      className={isHover[index] ? fullWidth : noWidth}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
};

export default ServicesPage;

export const Head: HeadFC = () => <title>Services</title>;
