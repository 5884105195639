// extracted by mini-css-extract-plugin
export var animateHowWeWorkTitle = "styles-module--animate-how-we-work-title--dcd72";
export var gifHowWeWork = "styles-module--gif-how-we-work--d4831";
export var gifHowWeWorkContainer = "styles-module--gif-how-we-work-container--3d271";
export var gifImageContainer = "styles-module--gif-image-container--2a9f1";
export var gifTitle = "styles-module--gifTitle--6aca5";
export var gifTitleHowWeWork = "styles-module--gif-title-how-we-work--eaf94";
export var howWeWorkContainer = "styles-module--how-we-work-container--d693b";
export var howWeWorkContents = "styles-module--how-we-work-contents--f7c60";
export var howWeWorkDescription = "styles-module--how-we-work-description--6723a";
export var howWeWorkImageContainer = "styles-module--how-we-work-image-container--ead04";
export var howWeWorkInfoContainer = "styles-module--how-we-work-info-container--87540";
export var howWeWorkTitle = "styles-module--how-we-work-title--6b852";
export var imageHowWeWork = "styles-module--image-how-we-work--c9acb";
export var titleFormat = "styles-module--title-format--6d498";
export var titlesHowWeWork = "styles-module--titles-how-we-work--1be7d";