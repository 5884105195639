import React from 'react';
import { JSXMapSerializer, PrismicRichText } from '@prismicio/react';
import { animated, useInView } from '@react-spring/web';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  howWeWorkContainer,
  gifHowWeWorkContainer,
  howWeWorkInfoContainer,
  titlesHowWeWork,
  gifTitleHowWeWork,
  gifHowWeWork,
  gifTitle,
  titleFormat,
  howWeWorkDescription,
  howWeWorkImageContainer,
  howWeWorkContents,
  imageHowWeWork,
  howWeWorkTitle,
  gifImageContainer,
  animateHowWeWorkTitle,
} from './styles.module.css';
import '../../../styles/global.css';

import { HowWeWorkData } from '../../../interfaces/howwework';

const HowWeWorkPage = ({ data }: HowWeWorkData) => {
  const { gif_how_we_work_title, how_we_work_gif, how_we_work_content } = data;
  const howWeWorkGif = how_we_work_gif?.gatsbyImageData;

  const headingSerializer: JSXMapSerializer = {
    heading1: ({ children }) => <h1 className={gifTitle}>{children}</h1>,
    heading2: ({ children }) => <h2 className={titlesHowWeWork}>{children}</h2>,
    label: ({ node, children }) => (
      <span className={node.data.label === 'title-format' ? titleFormat : ''}>
        {children}
      </span>
    ),
  };

  // How We Work Title Animation
  const [howWeWorkTitleRef, howWeWorkSprings] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 200,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      threshold: 0.1,
      rootMargin: '50px 0px 50px 0px',
    }
  );

  return (
    <div className={howWeWorkContainer}>
      <div className={gifHowWeWorkContainer}>
        <div className={gifImageContainer}>
          <GatsbyImage
            image={howWeWorkGif}
            alt={how_we_work_gif?.alt}
            className={gifHowWeWork}
          />
        </div>
        <div className={gifTitleHowWeWork} id="how-we-work-page">
          <animated.div
            ref={howWeWorkTitleRef}
            style={howWeWorkSprings}
            className={animateHowWeWorkTitle}
          >
            <PrismicRichText
              field={gif_how_we_work_title?.richText}
              components={headingSerializer}
            />
          </animated.div>
        </div>
      </div>
      <div className={howWeWorkInfoContainer}>
        {how_we_work_content.map((item) => {
          const howWeWorkImage = item?.how_we_work_image?.gatsbyImageData;
          return (
            <div className={howWeWorkContents}>
              <div className={howWeWorkTitle}>
                <PrismicRichText
                  field={item?.how_we_work_title?.richText}
                  components={headingSerializer}
                />
              </div>
              <div className={imageHowWeWork}>
                <GatsbyImage
                  image={howWeWorkImage}
                  alt={item?.how_we_work_image?.alt}
                  className={howWeWorkImageContainer}
                />
              </div>
              <div className={howWeWorkDescription}>
                <PrismicRichText
                  field={item?.how_we_work_description?.richText}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HowWeWorkPage;
