// extracted by mini-css-extract-plugin
export var aboutBottomContainer = "styles-module--about-bottom-container--3df0a";
export var aboutContainer = "styles-module--about-container--c9fc6";
export var aboutDescContainer = "styles-module--about-desc-container--79ba3";
export var aboutImage = "styles-module--about-image--e06f6";
export var aboutImageContainer = "styles-module--about-image-container--70998";
export var aboutImageTitleContainer = "styles-module--about-image-title-container--91347";
export var aboutImages = "styles-module--about-images--45a86";
export var aboutMeetContainer = "styles-module--about-meet-container--b8778";
export var aboutTitleContainer = "styles-module--about-title-container--0190e";
export var aboutTopContainer = "styles-module--about-top-container--cf724";
export var animateAboutUs = "styles-module--animate-about-us--3f372";
export var fontFormat = "styles-module--font-format--d1f43";
export var fullWidth = "styles-module--full-width--94e05";
export var imageBack = "styles-module--image-back--b0598";
export var imageFront = "styles-module--image-front--f9afc";
export var imageInfoService = "styles-module--image-info-service--5282d";
export var imageMiddle = "styles-module--image-middle--94423";
export var imageService = "styles-module--image-service--aca18";
export var infoContainer = "styles-module--info-container--752f9";
export var infoContentContainer = "styles-module--info-content-container--675a7";
export var infoParagraph = "styles-module--info-paragraph--f4602";
export var noWidth = "styles-module--no-width--67a08";
export var paragraphCard = "styles-module--paragraph-card--4ebe4";
export var paragraphCardBottom = "styles-module--paragraph-card-bottom--c291e";
export var paragraphCardTop = "styles-module--paragraph-card-top--0c9d9";
export var paragraphCardsContainer = "styles-module--paragraph-cards-container--5e838";
export var paragraphComponent = "styles-module--paragraph-component--fde33";
export var serviceCenter = "styles-module--service-center--78889";
export var serviceCenterBorder = "styles-module--service-center-border--92dee";
export var serviceImageContainer = "styles-module--service-image-container--db27a";
export var serviceImageTitleContainer = "styles-module--service-image-title-container--f415e";
export var serviceInfoList = "styles-module--service-info-list--75675";
export var serviceInfoParagraph = "styles-module--service-info-paragraph--af519";
export var serviceInfoParagraphHidden = "styles-module--service-info-paragraph-hidden--a4e8c";
export var serviceInfoTitle = "styles-module--service-info-title--fe926";
export var servicesContainer = "styles-module--services-container--acb12";
export var showBtn = "styles-module--show-btn--c80da";
export var slideInFromLeft = "styles-module--slideInFromLeft--1aae8";
export var teamImgBottom = "styles-module--team-img-bottom--72224";
export var teamImgTopContainer = "styles-module--team-img-top-container--cfe3b";
export var teamTitleContainer = "styles-module--team-title-container--08f39";
export var title = "styles-module--title--784d3";
export var titleContainer = "styles-module--title-container--8988f";
export var titleDescription = "styles-module--title-description--962e7";
export var titleWrapper = "styles-module--title-wrapper--20251";
export var weBelieveContainer = "styles-module--we-believe-container--bf763";