// extracted by mini-css-extract-plugin
export var animateIn = "styles-module--animateIn--ccd3e";
export var animateItem = "styles-module--animate-item--f11e2";
export var animatePortfolio = "styles-module--animate-portfolio--6cdb0";
export var buttonActive = "styles-module--button-active--3dda2";
export var portfolioContainer = "styles-module--portfolio-container--b3c6c";
export var portfolioDescription = "styles-module--portfolio-description--25502";
export var portfolioFilterButtons = "styles-module--portfolio-filter-buttons--33b8b";
export var portfolioImages = "styles-module--portfolio-images--d0a5c";
export var portfolioItemBox = "styles-module--portfolio-item-box--48f3a";
export var portfolioItemBoxTitle = "styles-module--portfolio-item-box-title--a5cb8";
export var portfolioItems = "styles-module--portfolio-items--1c477";
export var portfolioList = "styles-module--portfolio-list--4c45c";
export var portfolioListContainer = "styles-module--portfolio-list-container--635fc";
export var portfolioMain = "styles-module--portfolio-main--f7a11";
export var portfolioTitle = "styles-module--portfolio-title--f5cc5";
export var rightRedArrow = "styles-module--right-red-arrow--37eef";
export var viewContainer = "styles-module--view-container--588d0";