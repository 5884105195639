import React from 'react';
import { Link } from 'gatsby';
import { JSXMapSerializer, PrismicRichText } from '@prismicio/react';
import {
  heroContainer,
  heroTitleContainer,
  asteriskFormat,
  heroTitle,
  heroDescription,
  heroButtons,
  servicesButton,
  howWeWorkButton,
  heroImageContainer,
  heroImage,
} from './styles.module.css';
import '../../../styles/global.css';

import { HeroData } from '../../../interfaces/hero';
import { GatsbyImage } from 'gatsby-plugin-image';

const Hero = ({ data }: HeroData) => {
  const {
    home_text,
    hero_title_image,
    hero_services_btn,
    hero_how_we_work_btn,
    hero_description,
  } = data;

  const heroTitleImage = hero_title_image?.gatsbyImageData;

  const components: JSXMapSerializer = {
    label: ({ node, children }) => (
      <span
        className={node.data.label === 'asterisk-format' ? asteriskFormat : ''}
      >
        {children}
      </span>
    ),
    heading1: ({ children }) => <h1 className={heroTitle}>{children}</h1>,
    heading2: ({ children }) => <h1 className={heroDescription}>{children}</h1>,
  };

  return (
    <>
      <div className={heroContainer}>
        <div className={heroTitleContainer}>
          <div className={heroTitle}>
            <PrismicRichText
              field={home_text?.richText}
              components={components}
            />
            <div className={heroImageContainer}>
              <GatsbyImage
                image={heroTitleImage}
                alt={hero_title_image?.alt}
                className={heroImage}
              />
            </div>
          </div>
        </div>
        <div className={heroDescription}>
          <PrismicRichText
            field={hero_description?.richText}
            components={components}
          />
        </div>
        <div className={heroButtons}>
          <Link to="#services-page">
            <button className={servicesButton}>
              <PrismicRichText field={hero_services_btn?.richText} />
            </button>
          </Link>
          <Link to="#how-we-work-page">
            <button className={howWeWorkButton}>
              <PrismicRichText field={hero_how_we_work_btn?.richText} />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Hero;
