import React, { useState, useEffect } from 'react';
import { HeadFC, Link } from 'gatsby';
import { JSXMapSerializer, PrismicRichText } from '@prismicio/react';
import { animated, useInView } from '@react-spring/web';
import { PROJECT_BUTTONS } from '../../../constants/buttons';
import RedArrowView from '../../../assets/view-red-arrow.svg';
import {
  portfolioContainer,
  portfolioTitle,
  portfolioDescription,
  portfolioList,
  portfolioItems,
  portfolioItemBox,
  portfolioMain,
  portfolioListContainer,
  portfolioItemBoxTitle,
  portfolioFilterButtons,
  buttonActive,
  portfolioImages,
  viewContainer,
  rightRedArrow,
  animateItem,
  animatePortfolio,
} from './styles.module.css';
import '../../../styles/global.css';

import {
  IPortfolioData,
  IPortfolioDetailsPage,
} from '../../../interfaces/portfolio';
import { GatsbyImage } from 'gatsby-plugin-image';

const PortfolioPage = ({
  data,
  allPrismicPortfolioDetailsPage,
}: IPortfolioData) => {
  const { page_title, page_description } = data;

  const headingsSerializer: JSXMapSerializer = {
    heading1: ({ children }) => <h1 className={portfolioTitle}>{children}</h1>,
  };

  const [filters, setFilters] = useState([] as string[]);
  const [filteredProjects, setFilteredProjects] = useState<
    IPortfolioDetailsPage[]
  >([]);
  const [displayProjects, setDisplayProjects] = useState<
    IPortfolioDetailsPage[]
  >([]);
  const [portfolioTitles, setPortfolioTitles] = useState<string[]>([]);

  const findFilterIndex = (search: string) =>
    filters.findIndex((category) => category === search);

  const addToFilter = (categoryWord: string) => {
    const index = findFilterIndex(categoryWord);
    return setFilters(index >= 0 ? [] : [categoryWord]);
  };

  useEffect(() => {
    const projects = filters.length
      ? displayProjects.filter(
          (portfolio_details_page: IPortfolioDetailsPage) =>
            filters.includes(
              portfolio_details_page?.node?.data?.category_filter?.text.toLowerCase()
            )
        )
      : displayProjects;
    setFilteredProjects(projects);
  }, [filters]);

  useEffect(() => {
    const titles = Array.from(
      new Set(
        displayProjects.map((portfolio_details_page: IPortfolioDetailsPage) =>
          portfolio_details_page?.node?.data?.category_filter?.text.toLowerCase()
        )
      )
    );
    const filteredTitles = titles.filter((title) => title !== 'more');
    setPortfolioTitles([...filteredTitles, 'more']);
  }, [displayProjects]);

  useEffect(() => {
    setDisplayProjects(allPrismicPortfolioDetailsPage || []);
    setFilteredProjects(allPrismicPortfolioDetailsPage || []);
  }, []);

  const [portfolioRef, portfolioSprings] = useInView(
    () => ({
      from: {
        opacity: 0.2,
        y: 110,
      },
      to: {
        opacity: 1,
        y: 0,
      },
    }),
    {
      threshold: 0.25,
      rootMargin: '100px 0px 100px 0px',
    }
  );

  return (
    <main className={portfolioMain}>
      <animated.div
        ref={portfolioRef}
        style={portfolioSprings}
        className={animatePortfolio}
      >
        <div className={portfolioContainer} id="portfolio-page">
          <PrismicRichText
            field={page_title?.richText}
            components={headingsSerializer}
          />
          <div className={portfolioDescription}>
            <PrismicRichText field={page_description?.richText} />
          </div>

          <div className={portfolioFilterButtons}>
            <button
              onClick={() => setFilters([])}
              className={filters.length == 0 ? buttonActive : ''}
            >
              All
            </button>
            {portfolioTitles?.map((buttonTitle: string) => {
              return (
                <button
                  onClick={() => addToFilter(buttonTitle)}
                  className={
                    findFilterIndex(buttonTitle) >= 0 ? buttonActive : ''
                  }
                >
                  {buttonTitle}
                </button>
              );
            })}
          </div>
        </div>
      </animated.div>
      <div className={portfolioListContainer}>
        <div className={portfolioList}>
          {filteredProjects?.map(
            (portfolio_details_page: IPortfolioDetailsPage, index) => {
              const portfolioGridImage =
                portfolio_details_page?.node?.data?.portfolio_grid_image
                  ?.gatsbyImageData;
              const portfolioGridImageAlt =
                portfolio_details_page?.node?.data?.portfolio_grid_image?.alt;
              const portfolioGridTitles =
                portfolio_details_page?.node?.data?.project_detail_name?.text;
              const style = {
                '--my-css-var': index + 1,
              } as React.CSSProperties;
              return (
                <div
                  className={`${portfolioItems} ${animateItem}`}
                  key={portfolio_details_page?.node?.uid}
                  style={style}
                >
                  <Link to={`/portfolio/${portfolio_details_page?.node?.uid}`}>
                    <div className={portfolioItemBox}>
                      <GatsbyImage
                        image={portfolioGridImage}
                        alt={portfolioGridImageAlt}
                        className={portfolioImages}
                      />
                      <div className={portfolioItemBoxTitle}>
                        <p>{portfolioGridTitles}</p>
                        <div className={viewContainer}>
                          <p>View</p>
                          <div className={rightRedArrow}>
                            <RedArrowView />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            }
          )}
        </div>
      </div>
    </main>
  );
};

export default PortfolioPage;

export const Head: HeadFC = () => <title>Portfolio</title>;
