import { graphql, useStaticQuery } from 'gatsby';

export const useHeroQuery = () => {
  const { prismicHomepageV1 } = useStaticQuery(graphql`
    query HeroQuery {
      prismicHomepageV1 {
        data {
          home_text {
            richText
          }
          hero_title_image {
            alt
            gatsbyImageData(width: 60, height: 60)
          }
          hero_description {
            richText
          }
          hero_services_btn {
            richText
          }
          hero_how_we_work_btn {
            richText
          }
        }
      }
    }
  `);

  return prismicHomepageV1?.data;
};
