import { graphql, useStaticQuery } from 'gatsby';

export const useHowWeWorkQuery = () => {
  const { prismicHowwework } = useStaticQuery(graphql`
    query HowWeWorkQuery {
      prismicHowwework {
        data {
          gif_how_we_work_title {
            richText
          }
          how_we_work_gif {
            alt
            gatsbyImageData
          }
          how_we_work_content {
            how_we_work_image {
              alt
              gatsbyImageData
            }
            how_we_work_title {
              richText
            }
            how_we_work_description {
              richText
            }
          }
        }
      }
    }
  `);

  return prismicHowwework?.data;
};
