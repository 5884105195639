// extracted by mini-css-extract-plugin
export var animateSpotlight = "styles-module--animate-spotlight--84e41";
export var fontFormat = "styles-module--font-format--491c9";
export var longTitle = "styles-module--long-title--4b131";
export var projectCategoryDetails = "styles-module--project-category-details--c2a85";
export var projectDetailsContainer = "styles-module--project-details-container--98720";
export var projectHighlightsContainer = "styles-module--project-highlights-container--f0b2c";
export var projectHighlightsImage = "styles-module--project-highlights-image--831fe";
export var projectHighlightsInfoContainer = "styles-module--project-highlights-info-container--4cafd";
export var projectHighlightsTitle = "styles-module--project-highlights-title--f91bb";
export var projectHighlightsTitleContainer = "styles-module--project-highlights-title-container--dd0c7";
export var projectLocationDetails = "styles-module--project-location-details--472ef";
export var projectOtherDetails = "styles-module--project-other-details--9a861";
export var projectsContainer = "styles-module--projects-container--4e974";