import { graphql, useStaticQuery } from 'gatsby';

export const useServicesQuery = () => {
  const { prismicServicesPage } = useStaticQuery(graphql`
    query ServicePageQuery {
      prismicServicesPage {
        data {
          about_bottom {
            richText
          }
          about_description {
            richText
          }
          about_image_back {
            gatsbyImageData
            alt
          }
          about_image_front {
            alt
            gatsbyImageData
          }
          about_image_middle {
            alt
            gatsbyImageData
          }
          about_title {
            richText
          }
          about_title_image {
            alt
            gatsbyImageData(width: 60, height: 60)
          }
          about_top {
            richText
          }
          page_description {
            richText
          }
          page_title {
            richText
          }
          paragraph_description_bottom {
            richText
          }
          paragraph_description_top {
            richText
          }
          service_image {
            alt
            gatsbyImageData
          }
          service_info_group {
            service_info_image {
              alt
              gatsbyImageData
            }
            service_info_list {
              richText
            }
            service_info_paragraph {
              richText
              text
            }
            service_info_second_paragraph {
              richText
            }
            service_info_title {
              richText
            }
          }
          team_image_bottom {
            alt
            gatsbyImageData
            thumbnails {
              mobile {
                gatsbyImageData
              }
            }
          }
          team_image_top {
            alt
            gatsbyImageData
            thumbnails {
              mobile {
                gatsbyImageData
              }
            }
          }
          team_title {
            richText
          }
        }
      }
    }
  `);

  return prismicServicesPage?.data;
};
