import { graphql, useStaticQuery } from 'gatsby';

export const useProjectSpotlightsQuery = () => {
  const { prismicProjectspotlight } = useStaticQuery(graphql`
    query SpotlightQuery {
      prismicProjectspotlight {
        data {
          first_spotlight_title {
            richText
          }
          first_spotlight_image {
            alt
            gatsbyImageData
          }
          first_spotlight_project_category {
            richText
          }
          first_spotlight_project_category_name {
            richText
          }
          first_spotlight_project_location {
            richText
          }
          first_spotlight_project_location_name {
            richText
          }
          first_spotlight_other_info {
            richText
          }
          first_spotlight_other_info_name {
            richText
          }
          first_spotlight_link {
            url
          }
          second_spotlight_title {
            richText
          }
          second_spotlight_image {
            alt
            gatsbyImageData
          }
          second_spotlight_project_category {
            richText
          }
          second_spotlight_project_category_name {
            richText
          }
          second_spotlight_project_location {
            richText
          }
          second_spotlight_project_location_name {
            richText
          }
          second_spotlight_other_info {
            richText
          }
          second_spotlight_other_info_name {
            richText
          }
          second_spotlight_link {
            url
          }
          third_spotlight_title {
            richText
          }
          third_spotlight_image {
            alt
            gatsbyImageData
          }
          third_spotlight_project_category {
            richText
          }
          third_spotlight_project_category_name {
            richText
          }
          third_spotlight_project_location {
            richText
          }
          third_spotlight_project_location_name {
            richText
          }
          third_spotlight_other_info {
            richText
          }
          third_spotlight_other_info_name {
            richText
          }
          third_spotlight_link {
            url
          }
        }
      }
    }
  `);

  return prismicProjectspotlight?.data;
};
