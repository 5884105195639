import React from 'react';
import type { HeadFC } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/sections/Hero/hero';
// import ProjectHighlightsPage from '../components/sections/ProjectHighlights/projecthighlights';
import ProjectSpotlightsSection from '../components/sections/ProjectSpotlight/projectspotlight';
import HowWeWorkPage from '../components/sections/HowWeWork/howwework';
import ServicesPage from '../components/sections/Services/services';
import PortfolioPage from '../components/sections/Portfolio/portfolio';
import Footer from '../components/Footer';

import { useAnimationLoading } from '../hooks/use-animation-loading-query';
import { useServicesQuery } from '../hooks/use-services-query';
import { useHowWeWorkQuery } from '../hooks/use-how-we-work-query';
import { useHeroQuery } from '../hooks/use-hero-query';
import { usePortfolioPageQuery } from '../hooks/use-portfolio-query';
// import { useProjectHighlightsQuery } from '../hooks/use-project-highlights-query';
import { useProjectSpotlightsQuery } from '../hooks/use-spotlight-query';
import Loading from '../components/Loading';

import {
  container,
  loadingWrapper,
} from '../styles/pages/index/styles.module.css';

const IndexPage = () => {
  const loadingData = useAnimationLoading();
  const serviceData = useServicesQuery();
  const heroData = useHeroQuery();
  const [portfolioData, portfolioPagesData] = usePortfolioPageQuery();
  // const projectHighlightsData = useProjectHighlightsQuery();
  const projectSpotlightsData = useProjectSpotlightsQuery();
  const howWeWorkData = useHowWeWorkQuery();

  return (
    <Layout>
      <div className={loadingWrapper}>
        <Loading data={loadingData} />
      </div>
      <main>
        <Hero data={heroData} />
        <PortfolioPage
          data={portfolioData}
          allPrismicPortfolioDetailsPage={portfolioPagesData}
        />
        <ProjectSpotlightsSection data={projectSpotlightsData} />
        {/* <ProjectHighlightsPage
          allPrismicPortfolioDetailsPage={projectHighlightsData}
        /> */}
        <HowWeWorkPage data={howWeWorkData} />
        <ServicesPage data={serviceData} />
        <Footer />
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <SEO
    title={'OnPoint Dreamworks Inc.'}
    description={'We believe in Making a Difference.'}
    image={'/onpoint_image.png'}
    siteUrl={'https://www.onpointdreamworks.com'}
  />
);
